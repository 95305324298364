<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="showLogoExpat()" max-height="80px" max-width="80px" alt="logo" contain class="me-3"></v-img>

            <h2 class="text-2xl font-weight-semibold">TUKUPEDIA</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Welcome to Admin Page! 👋🏻</p>
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <form @submit.prevent="loginMember">
            <v-text-field v-model="admins.email" outlined label="Email" hide-details class="mb-3"></v-text-field>

            <v-text-field
              type="password"
              v-model="admins.password"
              outlined
              label="Password"
              hide-details
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox> -->

              <!-- forgot link -->
              <!-- <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a> -->
            </div>
            <button>
              <v-btn block color="primary" class="mt-6"> Login </v-btn>
            </button>
          </form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="347" height="285" :src="showImageKiri()"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" :src="showImageKanan()"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      admins: {
        email: '',
        password: '',
      },
      errors: [],

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    showImageKanan() {
      let LogoExpat = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/expat.svg`
      return LogoExpat
    },
    showImageKiri() {
      let LogoExpat = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/expat.png`
      return LogoExpat
    },
    showLogoExpat() {
      let LogoExpat = process.env.VUE_APP_ROOT_API + `/images/logo/logo.png`
      return LogoExpat
    },
    async loginMember(e) {
      this.$isLoading(true)
      if (this.$data.admins.email.length === 0) {
        this.errors.push('email wajib diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'email wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        })
      } else if (this.$data.admins.password.length === 0) {
        this.errors.push('Password wajib diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Password wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        // this.$isLoading(true)
        let uri = process.env.VUE_APP_ROOT_API + '/api/admin/login'

        // console.log(uri);
        // this.loadingPage = true;
        this.axios
          .post(uri, this.admins)
          .then(response => {
            this.$isLoading(false)
            this.messages = response.data.msg
            this.auth = response.data.auth
            this.tokens = response.data.key
            // console.log('AUTH : ' + process.env.VUE_APP_ROLE_ADMIN)
            if (this.auth == process.env.VUE_APP_ROLE_ADMIN) {
              console.log('AUTH : ' + this.auth)
              localStorage.setItem('token', response.data.key)
              this.$router.push('/admin/dashboard')
            } else {
              this.$swal.fire({
                title: 'Error',
                html: "Sorry, you can't access this page !",
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 3000,
              })
              localStorage.removeItem('token') //clear token atau key
            }
          })
          .catch(error => {
            this.$swal.fire({
              title: 'Error',
              html: error.response.data.msg,
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            })
            localStorage.removeItem('token') //clear token atau key
          })
        this.$isLoading(false)
        return true
      }

      e.preventDefault()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
